// @ts-check
import React, { useCallback } from "react";
// @ts-ignore VSCode reports type error but works fine: https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
import styles from "./Assignees.module.css";
// @ts-ignore VSCode reports type error but works fine: https://create-react-app.dev/docs/adding-images-fonts-and-files
import closeIcon from "../../assets/svgs/close-icon.svg";
import { enforceDecimalPlacesForNumberString } from "../../util/enforce-decimal-places-for-number-string";

// Ideally, this component would not know so much about each parent component's state.
// "option" should be named "selectedAssignee" to be clearer.
// "setSelected" should be named "setSelectedAssignees" to be clearer.
const Assignees = ({ option, setSelected, payment, disableCrossIcon = false }) => {
  const handleDelete = () => {
    setSelected(prevData => prevData.filter(item => item.key !== option.key));
  };

  const setCommissionInSelectedAssignees = useCallback(({ commission }) => {
    // "key" is employee ID (see Clients.js and Payments.js).
    setSelected(prev => prev.map(assignee => (assignee.key === option.key ? { ...assignee, commission } : assignee)));
  }, []);

  const onCommissionChange = event => {
    setCommissionInSelectedAssignees({ commission: event.target.value });
  };

  const handleBlur = useCallback(event => {
    const { value } = event.target;
    // Allow empty number input.
    if (value !== "") {
      // Number input should prevent non-numeric input other than empty string: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#using_number_inputs
      const commission = enforceDecimalPlacesForNumberString(value, 5);
      setCommissionInSelectedAssignees({ commission });
    }
  }, []);

  const commissionAmount = payment * option.commission * 0.01 + payment;
  return (
    <div className={styles.assigneesWrapper}>
      <div className={styles.assigneeName}>{option.value}</div>
      <div className={styles.commissionWrapper}>
        <div className={styles.assigneeCommission}>
          Commission:{" "}
          {payment && <span className={styles.commissionPrice}>${parseInt(commissionAmount).toFixed(2)}</span>}
        </div>
        <input
          className={styles.commissionInput}
          value={option.commission}
          type="number"
          onChange={onCommissionChange}
          onBlur={handleBlur}
        />
        <div className={styles.percentageCommission}>%</div>
        {!disableCrossIcon && <img className={styles.closeIcon} src={closeIcon} alt="icon" onClick={handleDelete} />}
      </div>
    </div>
  );
};

export default Assignees;
