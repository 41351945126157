import { createSlice } from "@reduxjs/toolkit";
// import styles from "../styles/Clients.module.css";
import { deleteClient, getClients } from "../thunk/client.thunk";
// import { loginAction } from '../thunk/login.thunk';

const initialState = {
  data: [],
  assignedTo: [],
  loading: false,
};

export const clientReducer = createSlice({
  name: "client",
  initialState,
  reducers: {
    logOut: state => {
      state.loggedIn = false;
    },
    adminLogout: state => {
      state.user = 2001;
    },
  },
  extraReducers: {
    [getClients.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getClients.pending]: state => {
      state.loading = true;
    },
    [getClients.rejected]: state => {
      state.loading = false;
    },
    [deleteClient.fulfilled]: state => {
      state.loading = false;
    },
    [deleteClient.pending]: state => {
      state.loading = true;
    },
    [deleteClient.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { logOut, adminLogout } = clientReducer.actions;
export default clientReducer.reducer;
