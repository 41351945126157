// @ts-ignore
import React, { useEffect, useState } from "react";
import styles from "../styles/Clients.module.css";

import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { AddNewButton, Assignees, Modal, SelectInput, Table, TextInput } from "../components";
import { addClient, getClients, updateClient } from "../store/thunk/client.thunk";
import { getContractors } from "../store/thunk/contractor.thunk";
import { convertStringToNumber } from "../util/convert-string-to-number";

const Clients = () => {
  const data = useSelector(state => state.client.data);
  const employees = useSelector(state => state.contractor.data);
  // "selectedOption" should be named "selectedAssignees" to be clearer.
  const [selectedOption, setSelectedOption] = useState([]);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [payment, setPayment] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEdited, setIsEdited] = useState({});
  const [createUpdateFlag, setCreateUpdateFlag] = useState(true);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(data);

  const validateForm = () => {
    // Validate that (first) name is not empty instead of guessing what punctuation characters are allowed by the other system Calder Capital uses for client names, c.f. https://regexr.com/8agdn
    // "lastName" appears to be unused.
    const isFirstNameValid = firstName.length > 0;

    const isSelectedOptionValid = Object.keys(selectedOption).length > 0; // Check if selectedOption is not empty

    const isValid = isFirstNameValid && isSelectedOptionValid;
    setIsButtonDisabled(!isValid);
  };

  const addUpdateClient = () => {
    const assignee = [];
    selectedOption.forEach(x => {
      assignee.push({ employee_id: x.key, employee_percentage: convertStringToNumber(x.commission) });
    });
    if (!createUpdateFlag) {
      dispatch(
        updateClient({
          data: {
            f_name: firstName,
            payment_set: payment,
            l_name: lastName,
            email,
            assignee,
          },
          id: id,
        })
      );
    } else {
      dispatch(
        addClient({
          f_name: firstName,
          payment_set: payment,
          l_name: lastName,
          email,
          assignee,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getClients());
    dispatch(getContractors());
  }, []);

  // Ideally, ESLint would be configured to validate the dependencies for hooks: https://github.com/facebook/react/tree/028c8e6cf5ce2a87147a7e03e503ce94c7a7a0cf/packages/eslint-plugin-react-hooks
  useEffect(() => {
    if (data) {
      setSearchData(data);
    }
  }, [data]);

  useEffect(() => {
    validateForm();
    if (Object.keys(isEdited).length) {
      setCreateUpdateFlag(false);
      const { f_name, l_name } = isEdited;
      setFirstName(f_name);
      setPayment(isEdited["payment_set"]);
      setLastName(l_name);
      setEmail(isEdited["email"]);
      setSelectedOption(
        isEdited["assignee"]?.map(x => {
          const emp = employees?.find(y => y._id === x.employee_id);
          return {
            key: emp._id,
            value: emp.f_name + " " + emp.l_name,
            commission: x.employee_percentage,
          };
        })
      );
      setId(isEdited["_id"]);
      setIsEdited("");
    }
  }, [firstName, payment, lastName, email, selectedOption, isEdited]);

  const getAssignees = arr => {
    let result = "";
    arr?.forEach((x, i) => {
      const emp = employees?.find(y => y._id === x.employee_id);
      result = result + ` ${emp?.f_name} ${emp?.l_name} ${arr.length === i + 1 ? "" : ","}`;
    });
    return result;
  };

  function handleSearchPayments(value) {
    setSearchData(() =>
      data?.filter(client => {
        const clientName = client?.f_name;
        if (clientName && clientName.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
        return false;
      })
    );
  }

  return (
    <div className={styles.clientsContainer}>
      <Modal
        modalTitle={"Client"}
        btnTitle={"Add Client"}
        onClick={addUpdateClient}
        disable={isButtonDisabled}
        createUpdateFlag={createUpdateFlag}>
        <TextInput
          label="Client Name"
          star="*"
          placeholder="Client Name"
          type="text"
          value={firstName}
          setValue={setFirstName}
        />
        <TextInput label="Payment" star="*" placeholder="Payment" type="number" value={payment} setValue={setPayment} />
        <SelectInput setSelected={setSelectedOption} selected={selectedOption}>
          {employees?.map(option => (
            <option key={option._id} value={option._id}>
              {option.f_name + " " + option.l_name}
            </option>
          ))}
        </SelectInput>
        {selectedOption.map(option => {
          return <Assignees key={option.key} option={option} setSelected={setSelectedOption} />;
        })}
      </Modal>
      <div className={styles.searchWrap}>
        <div className={styles.searchContainer}>
          <TextInput label="Search" star="" placeholder="Search Client" type="text" setValue={handleSearchPayments} />
        </div>
        <AddNewButton
          title="Add New Client"
          onClick={() => {
            setCreateUpdateFlag(true);
            setSelectedOption([]);
            setEmail("");
            setFirstName("");
            setPayment("");
            setLastName("");
            document.getElementById("modalId").click();
          }}
        />
      </div>
      <Table
        headings={["Name", "Date updated", "Assigned To", "Actions"]}
        column={[
          `f_name`,
          element => {
            return element?.updatedAt ? moment(element?.updatedAt).format("MMM DD, YYYY") : "";
          },
          element => {
            return getAssignees(element.assignee);
          },
        ]}
        data={searchData}
        title="Edit"
        componentTitle="Clients"
        setIsEdited={setIsEdited}
      />
    </div>
  );
};

export default Clients;
