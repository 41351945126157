import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../util/constants";

export const getContractors = createAsyncThunk("employee/getContractors", async selectedDate => {
  const token = localStorage.getItem("token");

  try {
    const res = await axios.get(`${baseUrl}/employee`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const filteredData = res?.data?.filter(item => {
      return (
        item?.role !== "admin" &&
        (!selectedDate ||
          (new Date(item.updatedAt) instanceof Date &&
            new Date(item.updatedAt).toISOString().split("T")[0] === selectedDate))
      );
    });

    return filteredData;
  } catch (error) {
    console.error(error);
  }
});

// This thunk seems to be unused.
// Ideally, the naming of either employee or contractor would be consistent.
export const addContractor = createAsyncThunk("employee/addEmployee", async (data, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(`${baseUrl}/employee`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res?.data) {
      dispatch(getContractors());
      toast("Contractor added successfully", { type: "success" });
    }

    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to add contractor: ${error.message}`, { type: "error" });
  }
});

// This thunk seems to be unused.
export const updateContractor = createAsyncThunk("employee/updateContractor", async ({ data, id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(`${baseUrl}/employee/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data) {
      dispatch(getContractors());
      toast("Contractor updated successfully", { type: "success" });
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to update contractor: ${error.message}`, { type: "error" });
  }
});

export const deleteContractor = createAsyncThunk("employee/deleteContractor", async ({ id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    await axios.delete(`${baseUrl}/employee/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getContractors());
    toast("Contractor deleted successfully", { type: "success" });
  } catch (error) {
    console.error(error);
    toast(`Failed to delete contractor: ${error.message}`, { type: "error" });
  }
});
