import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../util/constants";

export const getEarnings = createAsyncThunk("earning/myEarnings", async ({ userId, startDate, endDate }) => {
  const token = localStorage.getItem("token");
  try {
    const params = new URLSearchParams();
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    // Confusingly, "getEarnings" thunk calls "myCsvData" route on backend. Is "getCsvData" thunk unused?
    const res = await axios.get(`${baseUrl}/earning/myCsvData/${userId}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data;
  } catch (error) {
    console.error(error);
  }
});

export const getCsvData = createAsyncThunk("earning/myCsvData", async userId => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(`${baseUrl}/earning/myCsvData/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to download csv: ${error.message}`, { type: "error" });
  }
});

export const deleteEarning = createAsyncThunk("earning/delete", async ({ id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    await axios.delete(`${baseUrl}/earning/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getEarnings());
    toast("Earning deleted successfully", { type: "success" });
  } catch (error) {
    console.error(error);
    toast(`Failed to delete earning: ${error.message}`, { type: "error" });
  }
});
