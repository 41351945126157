import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { Button, RevenueCard, Table, TextInput } from "../components";
import styles from "../styles/Earnings.module.css";

import totalRevenueIcon from "../assets/svgs/total-revenue-icon.svg";
import { toast } from "react-toastify";
import clientsIcon from "../assets/svgs/avatar-icon.svg";
import { getEarnings } from "../store/thunk/earning.thunk";
import { formatDateForDateInput } from "../util/format-date-for-date-input";
import { convertDateInputStringToUtc } from "../util/convert-date-input-string-to-utc";

const Earnings = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.earning);
  const [filterStartDate, setFilterStartDate] = useState(getDefaultFilterStartDate());
  const [filterEndDate, setFilterEndDate] = useState(getDefaultFilterEndDate());
  const headers = [
    "Clients",
    // 'Clients Email',
    "Last Date Updated",
    "Commission Earned",
    "Employee Share",
  ];

  const currentDate = new Date();

  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = `${month}-${day}-${year}`;
  const fileName = data?.name + "  " + formattedDate;

  function noPaymentMsg() {
    toast("No payment found", { type: "error" });
  }

  function paymentSuccessMsg() {
    toast("CSV downloaded successfully", { type: "success" });
  }

  let CSVBtn;
  if (Array.isArray(data?.data) && data?.data.length > 0) {
    CSVBtn = (
      <CSVLink data={data?.data} headers={headers} onClick={paymentSuccessMsg} filename={fileName}>
        <Button title="Download in CSV" radius="16px" size="13px" />
      </CSVLink>
    );
  } else {
    CSVBtn = <Button title="Download in CSV" radius="16px" size="13px" onClick={noPaymentMsg} />;
  }

  // Ideally, ESLint should warn and fix the useEffect dependencies: https://maxrozen.com/react-hooks-eslint-plugin-saved-hours-debugging-useeffect
  useEffect(() => {
    const startDate = convertDateInputStringToUtc(filterStartDate);
    const endDate = convertDateInputStringToUtc(filterEndDate);
    dispatch(getEarnings({ userId, startDate, endDate }));
  }, [dispatch, filterEndDate, filterStartDate, userId]);

  return (
    <div className={styles.earningsContainer}>
      <div className={styles.revenuesHeader}>
        <div>
          <div className={styles.userName}>{data?.name}</div>
          <div className={styles.userEmail}>{data?.email}</div>
        </div>
        <TextInput label="Start Date" type="date" value={filterStartDate} setValue={setFilterStartDate} />
        <TextInput label="End Date" type="date" value={filterEndDate} setValue={setFilterEndDate} />
        <div className={styles.revenueCards}>
          <RevenueCard
            title="Total Revenue"
            revenue={
              data.totalEarnings?.toFixed(2)
              // getTotalEarning()
              // data?.totalEarning
              //   ? parseFloat(data.totalEarning).toFixed(2)
              //   : "N/A"
            }
            icon={totalRevenueIcon}
          />

          {/* <RevenueCard
            title="Monthly Revenue"
            revenue="$25k"
            icon={monthlyRevenueIcon}
          /> */}
          <RevenueCard
            title="Total Clients"
            revenue={
              // data?.data?.length
              data?.uniqueClients
            }
            icon={clientsIcon}
          />
        </div>
      </div>

      <Table
        headings={[
          "Clients",
          // "Client Email",
          "Last Date Updated",
          "Commission Earned",
          "Contractor Share",
          "Actions",
        ]}
        componentTitle="Earnings"
        data={data?.data}
        column={[
          element => {
            return element[0];
          },
          element => {
            return element[1];
          },
          element => {
            const parts = element[2].split(" ");
            let result = "";

            if (parts.length === 2) {
              const dollarSign = parts[0];
              let number = parts[1];

              number = parseFloat(number).toFixed(2);

              result = dollarSign + " " + number;
            } else {
              result = "N/A";
            }

            return result;
          },
          element => {
            return element[3];
          },
        ]}
      />

      <div className={styles.earningsButtonWrapper}>{CSVBtn}</div>
    </div>
  );
};

function getDefaultFilterStartDate() {
  const sixMonthsAgo = moment().subtract(6, "months").toDate();
  return formatDateForDateInput(sixMonthsAgo);
}

function getDefaultFilterEndDate() {
  const today = new Date();
  return formatDateForDateInput(today);
}

export default Earnings;
