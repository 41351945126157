// @ts-ignore
import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { AddNewButton, Assignees, Button, Modal, Table, TextInput } from "../components";
import { getClients } from "../store/thunk/client.thunk";
import { getContractors } from "../store/thunk/contractor.thunk";
import { addPayment, getCsvDataPayment, getPayments, updatePayment } from "../store/thunk/payment.thunk";
import styles from "../styles/Payments.module.css";
import { formatDateForDateInput, formatUtcDateStringForDateInput } from "../util/format-date-for-date-input";
import { convertDateInputStringToUtc } from "../util/convert-date-input-string-to-utc";
import { formatUtcDateStringAsDateOnly } from "../util/format-utc-date-string-as-date-only";
import { convertStringToNumber } from "../util/convert-string-to-number";

const Payments = () => {
  const dispatch = useDispatch();
  const options = [];
  const payments = useSelector(state => state.payment.payments);
  const clients = useSelector(state => state.client.data);
  const employees = useSelector(state => state.contractor.data);

  clients?.forEach(element => {
    const employeeDetail = element.employeeDetail ? ` ${element.employeeDetail}` : "";

    options.push({
      value: element._id,
      label: `${element.f_name} ${element.l_name}${employeeDetail}`,
    });
  });

  const [id, setId] = useState("");
  const [isEdited, setIsEdited] = useState({});
  // "selectedOption" should be named "selectedAssignees" to be clearer.
  const [selectedOption, setSelectedOption] = useState([]);
  const [clientId, setClientId] = useState("");
  const [date, setDate] = useState("");
  const [showStartDate, setShowStartDate] = useState(getDefaultShowStartDate());
  const [showEndDate, setShowEndDate] = useState(getDefaultShowEndDate());
  const [payment, setPayment] = useState();
  const [createUpdateFlag, setCreateUpdateFlag] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formEdit, setFormEdit] = useState(false);
  const [showClient, setShowClient] = useState("");

  useEffect(() => {
    const date = {
      startDate: convertDateInputStringToUtc(showStartDate),
      endDate: convertDateInputStringToUtc(showEndDate),
    };
    dispatch(getPayments(date));
    // Ideally, the CSV would not be downloaded until the download button is clicked instead of each time a filter date input is changed.
    dispatch(getCsvDataPayment(date));
  }, [showEndDate, showStartDate]);

  useEffect(() => {
    dispatch(getClients());
    dispatch(getContractors());
  }, []);

  useEffect(() => {
    setSelectedOption([]);
    getEmployees(clientId);
  }, [clientId]);

  useEffect(() => {
    validateForm();
    // Consider using useCallback on click edit instead of useEffect reacting to isEdited changing.
    if (Object.keys(isEdited).length) {
      setCreateUpdateFlag(false);
      setFormEdit(true);
      const { date, assignee } = isEdited;
      setShowClient(getClientName(clientId));
      setDate(formatUtcDateStringForDateInput(date));
      setClientId(isEdited["clientId"]);
      setPayment(Number(isEdited["payment"]));
      setIsEdited("");
      setId(isEdited["_id"]);
      setSelectedOption([]);
      assignee.map(x => {
        setSelectedOption(prevState => {
          const employee = employees.find(employee => employee._id === x.employee_id);
          return [
            ...prevState,
            {
              key: x.employee_id,
              value: `${employee.f_name} ${employee.l_name}`,
              commission: x.employee_percentage,
            },
          ];
        });
      });
    }
  }, [clientId, date, selectedOption, isEdited]);

  const getEmployees = clientId => {
    const client = clients?.find(client => client._id === clientId);
    setPayment(client?.payment_set || "");
    if (client) {
      client?.assignee.map(x => {
        setSelectedOption(prevState => {
          const employee = employees.find(employee => employee._id === x.employee_id);
          return [
            ...prevState,
            {
              key: x.employee_id,
              value: `${employee.f_name} ${employee.l_name}`,
              commission: x.employee_percentage,
            },
          ];
        });
      });
    }
  };

  const handleAddPayment = () => {
    const assignee = [];
    selectedOption.forEach(x => {
      assignee.push({ employee_id: x.key, employee_percentage: convertStringToNumber(x.commission) });
    });

    if (!createUpdateFlag) {
      dispatch(
        updatePayment({
          data: {
            clientId,
            date: convertDateInputStringToUtc(date),
            payment,
            assignee,
          },
          id: id,
        })
      );
    } else {
      dispatch(
        addPayment({
          clientId,
          date: convertDateInputStringToUtc(date),
          payment,
          assignee,
        })
      );
    }

    resetStates();
  };

  const validateForm = () => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Regular expression for yyyy-mm-dd format
    const paymentRegex = /^\d+(\.\d+)?$/;

    const isValidDate = dateRegex.test(date);
    const isValidPayment = paymentRegex.test(payment);
    const isValidName = clientId !== "";

    const isValid = isValidName && isValidDate && isValidPayment;
    setIsButtonDisabled(!isValid);
  };

  const getClientName = id => {
    const client = clients?.find(x => x._id == id);
    if (client) {
      return `${client.f_name} ${client.l_name}`;
    } else {
      return "";
    }
  };

  const getEmpName = id => {
    const emp = employees?.find(x => x._id === id);
    if (emp) {
      return `${emp.f_name}`;
    } else {
      return "";
    }
  };

  const renderEmployeeList = assignees => {
    return assignees.map(assignee => (
      <div
        key={assignee.employee_id}
        style={{
          padding: 5,
        }}>
        <span>{getEmpName(assignee.employee_id)}</span>
      </div>
    ));
  };

  const renderEmployeePercentage = assignees => {
    return assignees.map(assignee => (
      <div
        key={assignee.employee_id}
        style={{
          padding: 5,
        }}>
        <span>{assignee.employee_percentage}%</span>
      </div>
    ));
  };

  const renderEmployeeAmount = (assignees, payment) => {
    return assignees?.map(assignee => (
      <div
        key={assignee?.employee_id}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 5,
        }}>
        <span>{((assignee?.employee_percentage / 100) * payment).toFixed(2)} USD</span>
      </div>
    ));
  };

  const resetStates = () => {
    setClientId("");
    setPayment("");
    setShowClient("");
    setDate("");
    setSelectedOption([]);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      // width: 300, // Set your desired width
      minHeight: 45, // Set your desired height
    }),
  };

  return (
    <div className={styles.paymentsContainer}>
      <Modal
        modalTitle="Payment"
        disable={isButtonDisabled}
        onClick={handleAddPayment}
        createUpdateFlag={createUpdateFlag}>
        <Select
          className={styles.reactSelectSingle}
          styles={customStyles}
          label="Client Name"
          placeholder={formEdit ? showClient : "Client Name"}
          onChange={e => {
            setClientId(e.value);
          }}
          isSearchable={true}
          required
          name="Client Name"
          options={formEdit ? [] : options}
          value={formEdit ? showClient : undefined}
        />
        <TextInput label="Payment" star="*" placeholder="Payment" type="number" value={payment} setValue={setPayment} />
        <TextInput label="Date" star="*" type="date" value={date} setValue={setDate} />
        {selectedOption.map(option => {
          return <Assignees key={option.key} option={option} setSelected={setSelectedOption} disableCrossIcon />;
        })}
      </Modal>
      <div className={styles.paymentHeader}>
        <div className={styles.dateRanger}>
          <TextInput label="Start Date" type="date" value={showStartDate} setValue={setShowStartDate} />
          <TextInput label="End Date" type="date" value={showEndDate} setValue={setShowEndDate} />
        </div>
        <AddNewButton
          title="Add New Payment"
          onClick={() => {
            setFormEdit(false);
            resetStates();
            setCreateUpdateFlag(true);
            document.getElementById("modalId").click();
          }}
        />
      </div>
      <Table
        headings={["Client Name", "Date", "Payment", "Contractor Name", "Contractor Percentage", "Amount", "Actions"]}
        column={[
          element => getClientName(element?.clientId),
          element => formatUtcDateStringAsDateOnly(element.date),
          element => parseFloat(element.payment).toFixed(2),
          element => renderEmployeeList(element?.assignee),
          element => renderEmployeePercentage(element?.assignee),
          element => renderEmployeeAmount(element?.assignee, element?.payment),
        ]}
        data={payments}
        title="Edit"
        componentTitle="Payments"
        setIsEdited={e => {
          setClientId(e.clientId);
          setIsEdited(e);
        }}
      />
      <DownloadCsv showStartDate={showStartDate} showEndDate={showEndDate} />
    </div>
  );
};

function getDefaultShowStartDate() {
  const oneMonthAgo = moment().subtract(1, "months").toDate();
  return formatDateForDateInput(oneMonthAgo);
}

function getDefaultShowEndDate() {
  const today = new Date();
  return formatDateForDateInput(today);
}

function DownloadCsv({ showStartDate, showEndDate }) {
  const csvData = useCsvData();
  const csvHeaders = useCsvHeaders();
  const csvFilename = useCsvFilename({ showStartDate, showEndDate });
  const csvDataExists = useCsvDataExists({ csvData });

  const handleClickNoDataExists = useCallback(() => {
    toast("No payment found", { type: "error" });
  });
  const handleClickDataExists = useCallback(() => {
    toast("CSV downloaded successfully", { type: "success" });
  });

  // Ideally, the data would not be downloaded until the download button is clicked instead of each time a filter date input is changed.
  // However, we could disable the button until the data is fetched successfully.
  if (csvDataExists) {
    return (
      <DownloadCsvButtonWrapper>
        {/* Render an anchor element to match style of CSVLink component */}
        <a>
          <DownloadCsvButton onClick={handleClickNoDataExists} />
        </a>
      </DownloadCsvButtonWrapper>
    );
  }

  // react-csv's CSVLink components renders an anchor element that can be clicked: https://github.com/react-csv/react-csv/blob/d1153c101b95c109b5bcf5a5c2a370a81d522f1e/src/components/Link.jsx#L102-L111
  return (
    <DownloadCsvButtonWrapper>
      <CSVLink data={csvData} headers={csvHeaders} filename={csvFilename}>
        <DownloadCsvButton onClick={handleClickDataExists} />
      </CSVLink>
    </DownloadCsvButtonWrapper>
  );
}

function DownloadCsvButtonWrapper({ children }) {
  return <div className={styles.paymentButtonWrapper}>{children}</div>;
}

function DownloadCsvButton({ onClick }) {
  return <Button title="Download CSV" radius="16px" size="13px" onClick={onClick} />;
}

function useCsvData() {
  const csvData = useSelector(state => state.payment.csvData);
  return useMemo(() => {
    // Ideally, the backend would just return the property names needed to avoid extra mental mapping.
    return csvData.map(row => ({
      contractorName: row?.employeeDetail?.name,
      date: row?.date,
      name: row?.name,
      amount: row?.employeeDetail?.amount,
      percentage: row?.employeeDetail?.percentage,
      payment: row?.payment,
    }));
  }, [csvData]);
}

function useCsvHeaders() {
  return useMemo(() => [
    { label: "Client Name", key: "name" },
    { label: "Date", key: "date" },
    { label: "Payment", key: "payment" },
    { label: "Contractor Name", key: "contractorName" },
    { label: "Contractor Percentage", key: "percentage" },
    { label: "Contractor Amount", key: "amount" },
  ]);
}

function useCsvFilename({ showStartDate, showEndDate }) {
  return useMemo(() => {
    let fileName = "payment";
    if (showStartDate) {
      fileName += ` from ${showStartDate}`;
    }
    if (showEndDate) {
      fileName += ` to ${showEndDate}`;
    }
    return fileName;
  }, [showStartDate, showEndDate]);
}

function useCsvDataExists({ csvData }) {
  return useMemo(() => !Array.isArray(csvData) || csvData.length <= 0, [csvData]);
}

export default Payments;
