import { createSlice } from "@reduxjs/toolkit";
import { getCsvDataPayment, getPayments } from "../thunk/payment.thunk";

const initialState = {
  payments: [],
  csvData: [],
  loading: false,
};

export const paymentReducer = createSlice({
  name: "payment",
  initialState,
  reducers: {
    logOut: state => {
      state.loggedIn = false;
    },
    adminLogout: state => {
      state.user = 2001;
    },
  },
  extraReducers: {
    [getPayments.fulfilled]: (state, { payload }) => {
      state.payments = payload;
      state.loading = false;
    },
    [getPayments.pending]: state => {
      state.loading = true;
    },
    [getPayments.rejected]: state => {
      state.loading = false;
    },
    [getCsvDataPayment.fulfilled]: (state, { payload }) => {
      state.csvData = payload;
      state.loading = false;
    },
    [getCsvDataPayment.pending]: state => {
      state.loading = true;
    },
    [getCsvDataPayment.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { logOut, adminLogout } = paymentReducer.actions;
export default paymentReducer.reducer;
