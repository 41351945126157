// @ts-check

/**
 * @param {string} value
 * @returns {number | null} number if string is a valid floating point number, otherwise null.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat
 */
export function convertStringToNumber(value) {
  // Use "parseFloat" instead of "parseInt" to allow for decimals.
  const parsed = parseFloat(value);
  return Number.isNaN(parsed) ? null : parsed;
}
