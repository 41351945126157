/**
 * @param {string} dateInputString Date string in "yyyy-mm-dd" format or empty string if input is cleared.
 * @returns {string} Date string in UTC time and format if input is not empty, otherwise an empty string.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value HTML input with type "date" has value in the format "yyyy-mm-dd" if not cleared. If cleared, the value is an empty string.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#datestring JS Date constructor takes a date string.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format "When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time."
 */
export function convertDateInputStringToUtc(dateInputString) {
  // If string is not empty, convert to UTC time and then to UTC format since although only the date (not time) matters, MongoDB doesn't have a date-only type.
  return dateInputString ? new Date(dateInputString).toISOString() : "";
}
