import { createSlice } from "@reduxjs/toolkit";
import { getCsvData, getEarnings } from "../thunk/earning.thunk";

const initialState = {
  data: {
    data: [],
    totalEarnings: 0,
    uniqueClients: 0,
    name: "",
    email: "",
  },
  csvData: [],
  loading: false,
};

export const earningReducer = createSlice({
  name: "earning",
  initialState,
  reducers: {
    logOut: state => {
      state.loggedIn = false;
    },
    adminLogout: state => {
      state.user = 2001;
    },
  },
  extraReducers: {
    [getEarnings.fulfilled]: (state, { payload }) => {
      // Confusingly, "getEarnings" thunk calls "myCsvData" route on backend but updates "data" in reducer. Is "csvData" in reducer unused?
      state.data = payload;
      state.loading = false;
    },
    [getEarnings.pending]: state => {
      state.loading = true;
    },
    [getEarnings.rejected]: state => {
      state.loading = false;
    },
    [getCsvData.fulfilled]: (state, { payload }) => {
      state.csvData = payload;
      state.loading = false;
    },
    [getCsvData.pending]: state => {
      state.loading = true;
    },
    [getCsvData.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { logOut, adminLogout } = earningReducer.actions;
export default earningReducer.reducer;
