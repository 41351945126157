import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../util/constants";

export const getClients = createAsyncThunk("client/getClients", async () => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(`${baseUrl}/client`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data;
  } catch (error) {
    console.error(error);
  }
});

export const addClient = createAsyncThunk("client/addClient", async (data, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(`${baseUrl}/client`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data) {
      dispatch(getClients());
      toast("Client added successfully", { type: "success" });
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to add client: ${error.message}`, { type: "error" });
  }
});

export const updateClient = createAsyncThunk("client/updateClient", async ({ data, id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(`${baseUrl}/client/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data) {
      dispatch(getClients());
      toast("Client updated successfully", { type: "success" });
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to update client: ${error.message}`, { type: "error" });
  }
});

export const deleteClient = createAsyncThunk("client/deleteClient", async ({ id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    await axios.delete(`${baseUrl}/client/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getClients());
    toast("Client deleted successfully", { type: "success" });
  } catch (error) {
    console.error(error);
    toast(`Failed to delete client: ${error.message}`, { type: "error" });
  }
});
