import axios from "axios";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./App.module.css";
import Layout from "./layout/Layout";
import { Clients, Contractors, Earnings, Login, Payments } from "./pages";

function ProtectedRoute({ path, element }) {
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");
  const isLoggedIn = !!token;

  if (!isLoggedIn && path !== "/") {
    return <Navigate to="/" replace />;
  } else if (isLoggedIn && path === "/") {
    console.log("getting there");
    if (role == "admin") {
      return <Navigate to="/clients" replace />;
    } else {
      return <Navigate to={`/earnings/${userId}`} replace />;
    }
  } else {
    return element;
  }
}

function App() {
  return (
    <div className={styles.app}>
      <Layout>
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<Login />} path="/" />} />
          <Route path="/clients" element={<ProtectedRoute element={<Clients />} path="/clients" />} />
          <Route path="/payments" element={<ProtectedRoute element={<Payments />} path="/payments" />} />
          <Route path="/contractors" element={<ProtectedRoute element={<Contractors />} path="/contractors" />} />
          <Route path="/earnings/:userId" element={<ProtectedRoute element={<Earnings />} path="/earnings" />} />
        </Routes>
      </Layout>
      <ToastContainer />
    </div>
  );
}

export default App;
