/**
 * @param {string} numberString
 * @param {number} decimalPlaces
 * @returns {string} The number string truncated to the specified number of decimal places.
 * @throws {Error} If the number string is not a number.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat#return_value Returns a floating point number, or NaN if the string can't be converted to a number.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#description If necessary, rounds the number and/or pads it with zeros so it has the specified length.
 */
export function enforceDecimalPlacesForNumberString(numberString, decimalPlaces) {
  const result = parseFloat(numberString);
  if (Number.isNaN(result)) {
    throw new Error(`"${numberString}" is not a number.`);
  } else {
    return result.toFixed(decimalPlaces);
  }
}
