import { createSlice } from "@reduxjs/toolkit";
// import { getClients } from '../thunk/client.thunk';
import { getContractors } from "../thunk/contractor.thunk";

const initialState = {
  data: [],
  loading: false,
};

export const contractorReducer = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    logOut: state => {
      state.loggedIn = false;
    },
    adminLogout: state => {
      state.user = 2001;
    },
  },
  extraReducers: {
    [getContractors.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getContractors.pending]: state => {
      state.loading = true;
    },
    [getContractors.rejected]: state => {
      state.loading = false;
    },
  },
});

export const { logOut, adminLogout } = contractorReducer.actions;
export default contractorReducer.reducer;
