import moment from "moment";

const formatString = "YYYY-MM-DD";

/**
 * @param {Date} date
 * @returns {string} The date formatted as "yyyy-mm-dd" for use in an HTML input with type "date".
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value HTML input with type "date" expects the value to be in the format "yyyy-mm-dd".
 * @see https://momentjs.com/docs/#/displaying/format/ Moment.js is case-sensitive, i.e. "M" is for month and "m" is for minute.
 */
export function formatDateForDateInput(date) {
  return moment(date).format(formatString);
}

/**
 * @param {string} utcDateString
 * @returns {string}
 *
 * @see https://momentjs.com/docs/#/parsing/utc/ Moment.js can parse and display in UTC instead of default local time.
 */
export function formatUtcDateStringForDateInput(utcDateString) {
  return moment.utc(new Date(utcDateString)).format(formatString);
}
