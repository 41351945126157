import moment from "moment";

/**
 * @param {string} utcDateString
 * @returns {string}
 *
 * @see https://momentjs.com/docs/#/parsing/utc/
 */
export function formatUtcDateStringAsDateOnly(utcDateString) {
  // Parse date in UTC time instead of local time since only the date (not time) matters but MongoDB doesn't have a date-only type.
  return utcDateString ? moment.utc(utcDateString).format("DD MMM, YYYY") : "";
}
