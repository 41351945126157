// @ts-ignore
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../util/constants";
import { getClients } from "./client.thunk";

export const getPayments = createAsyncThunk("payments/getPayments", async (date, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    let query = "";
    if (date) {
      query = `?startDate=${date?.startDate}&endDate=${date?.endDate}`;
    }
    const res = await axios.get(`${baseUrl}/payment${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data) {
      dispatch(getClients());
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to download payments: ${error.message}`, { type: "error" });
  }
});

export const getCsvDataPayment = createAsyncThunk("payments/myCsvData", async date => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(`${baseUrl}/payment/csv?startDate=${date.startDate}&endDate=${date.endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Ideally, this transformation should be done in the backend since this is the only place that route is called.
    const transformedData = [];
    res?.data.forEach(item => {
      const employeeDetails = item.employeeDetail.map((emp, index) => ({
        name: index === 0 ? item.name : "", // Display name only in the first row of employee detail
        date: index === 0 ? item.date : "",
        payment: index === 0 ? item.payment : "",
        employeeDetail: emp,
      }));
      transformedData.push(...employeeDetails);
    });

    return transformedData;
  } catch (error) {
    console.error(error);
    toast(`Failed to download CSV: ${error.message}`, { type: "error" });
  }
});

export const deletePayment = createAsyncThunk("payments/deletePayment", async ({ id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    await axios.delete(`${baseUrl}/payment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getPayments());
    toast("Payment deleted successfully", { type: "success" });
  } catch (error) {
    console.error(error);
    toast(`Failed to delete payment: ${error.message}`, { type: "error" });
  }
});

export const updatePayment = createAsyncThunk("payments/updatePayment", async ({ data, id }, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(`${baseUrl}/payment/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data) {
      console.log("first");
      dispatch(getPayments());
      toast("Payment updated successfully", { type: "success" });
    }
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to update payment: ${error.message}`, { type: "error" });
  }
});

export const addPayment = createAsyncThunk("payments/addClient", async (data, { dispatch }) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(`${baseUrl}/payment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getPayments());
    toast("Payment added successfully", { type: "success" });
    return res?.data;
  } catch (error) {
    console.error(error);
    toast(`Failed to add payment: ${error.message}`, { type: "error" });
  }
});
